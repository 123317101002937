import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { id: "components-layout-demo-top-side-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_the_header),
      _createVNode(_component_router_view),
      _createVNode(_component_the_footer)
    ]),
    _: 1
  }))
}