import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px' } }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createVNode(_component_a_form, {
                layout: "inline",
                model: _ctx.param
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.param.loginName,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.param.loginName) = $event)),
                        placeholder: "登陆名"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleQuery({page: 1, size: _ctx.pagination.pageSize})))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 查询 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.add()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 新增 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createVNode(_component_a_table, {
              columns: _ctx.columns,
              "row-key": record => record.id,
              "data-source": _ctx.users,
              pagination: _ctx.pagination,
              loading: _ctx.loading,
              onChange: _ctx.handleTableChange
            }, {
              action: _withCtx(({ text, record }) => [
                _createVNode(_component_a_space, { size: "small" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: ($event: any) => (_ctx.resetPassword(record))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 重置密码 ")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: ($event: any) => (_ctx.edit(record))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 编辑 ")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_a_popconfirm, {
                      title: "删除后不可恢复，确认删除?",
                      "ok-text": "是",
                      "cancel-text": "否",
                      onConfirm: ($event: any) => (_ctx.handleDelete(record.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { type: "danger" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 删除 ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["columns", "row-key", "data-source", "pagination", "loading", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      title: "用户表单",
      visible: _ctx.modalVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalVisible) = $event)),
      "confirm-loading": _ctx.modalLoading,
      onOk: _ctx.handleModalOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.user,
          "label-col": { span: 6 },
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "登陆名" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.user.loginName,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.loginName) = $event)),
                  disabled: !!_ctx.user.id
                }, null, 8, ["value", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "昵称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.user.name,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _withDirectives(_createVNode(_component_a_form_item, { label: "密码" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.user.password,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.password) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.user.id]
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"]),
    _createVNode(_component_a_modal, {
      title: "重置密码",
      visible: _ctx.resetModalVisible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.resetModalVisible) = $event)),
      "confirm-loading": _ctx.resetModalLoading,
      onOk: _ctx.handleResetModalOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.user,
          "label-col": { span: 6 },
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "新密码" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.user.password,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.password) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"])
  ], 64))
}