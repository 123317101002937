import { createStore } from 'vuex'

declare let SessionStorage: any;
const USER = "USER"

const store = createStore({
  state: {
    user:SessionStorage.get(USER) || {}  // 避免空指针异常
  },
  // mutations 同步的
  mutations: {
    setUser(state,user){
      state.user = user;
      SessionStorage.set(USER,user)
    }
  },
  // action 支持异步
  actions: {
  },
  modules: {
  }
});

export default store;
